.projects-container {
  display: flex;
  flex-direction: column;
  padding-bottom: 5%;
  margin-left: 10%;
  margin-right: 10%;
  text-align: left;
}

.projects-container .projects-grid {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  margin-top: 50px;
  grid-gap: 50px;
}

.projects-container .projects-grid .project {
  text-align: left;
}

.project h2 {
  color: black;
}

.project h2:hover {
  text-decoration: underline;
}
.project h2.static:hover {
  text-decoration: none;
}

/* .project-tag {
  font-size: 0.8em;
  background-color: #F1F1F1;
  color: #838383;
  font-family: 'Roboto Mono', 'Consolas', monospace;
  font-weight: bold;
  border-radius: 5px;
  padding: 5px 8px;
  display: inline-block;
  max-width: 90%;
  text-overflow: ellipsis;
  overflow: hidden;
} */

.zoom {
  transition: transform .4s;
  transition-timing-function: ease-out;
}

.round {
  border-radius: 3em;
  margin: 0 auto;
}

.zoom:hover {
  transform: scale(1.05);
}

#project a {
  color: #ac3b61;
  font-weight: bold;
}

#project a:hover {
  color: #b1667f;
}

@media screen {
  @media (max-width: 700px) {
    .projects-container {
      display: block;
      /* padding-left: 5%;
      padding-right: 5%; */
    }
    .projects-container .projects-grid {
      display: block;
    }
    .projects-container .projects-grid .project {
      padding-bottom: 5%;
    }
  }
}