.skills-container {
  width: 80vw;
  display: flex;
  flex-direction: column;
  padding-bottom: 5%;
  text-align: left;
}

.skills-container .skills-grid {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  /* margin-top: 50px; */
  grid-gap: 50px;
}

div.skill {
    margin-top: 35px;
}

.skills-container .skills-grid .skill {
  align-items: center;
  text-align: left;
}

@media screen {
  @media (max-width: 567px) {
    .skills-container h1 {
      padding-left: 5%;
      padding-right: 5%;
    }
    .skills-container {
      display: initial;
    }
    .skills-container .skills-grid {
      display: initial;
    }
    .skills-container .skills-grid .skill {
      align-items: center;
      padding-left: 5%;
      margin-right: 5%;
      margin-bottom: 5%;
    }
  }

  /* between 567 and 700 px */
  @media (min-width: 567px) and (max-width: 700px) {
    .skills-container {
      margin-left: 5%;
    }
  }
}
